import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import { viewports } from "../theme/vars"
import CheckMateFeatures from "../components/checkmate-features"
import CheckMateFeatures1 from "../components/checkmate-features-2"
import SectionBlue from "../components/section-blue"
import LargeImageSection from "../components/other-vs-lamb"
import LastSection from "../components/other-vs-lamb/section-hands"

const title = `Bryan Post \n The Parenting App`
const subtitle="Interactive Solution for Parenting Challenging Children."
const copyParagraph = "Bryan Post – The Parenting App is here to help you stay regulated in the midst of your child’s stressful behaviors, encouraging you to respond in a way that keeps you in a relationship, deepens your understanding and helps you dwell in a space of love."

const IndexPage = () => {

  return (
    <Layout>
      <SEO title="Bryan Post The Parenting App" />
      <Hero title={title} subtitle={subtitle} copyParagraph={copyParagraph}/>

      <section>
        <LargeImageSection/>
      </section>

      <CheckMateFeatures />
      <CheckMateFeatures1 />
      <SectionBlue />

      <section style={{marginTop: 60}}>
        <LastSection/>
      </section>

      <form
        name="contact"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        method="post"
        hidden
      >
        <input type="text" name="name" />
        <input type="text" name="email" />
        <input type="text" name="phone" />
        <input type="text" name="loan-type" />
      </form>

      <style jsx global>{`
        .learn-more-sticky-wrapper {
          position: fixed;
          left: -500px;
          top: 30vh;
          transition: all 1s ease-in-out;
          z-index: 10;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 45px 0 45px 130px;
          border-color: transparent transparent transparent #1D2033;
          cursor: pointer;
        }
        .learn-more-sticky-wrapper.visible {
          left: 0;
          transition: all 1s ease-in-out;
        }
        .learn-more-sticky-wrapper:after {
          content: "Learn more";
          color: white;
          position: absolute;
          bottom: -10px;
          left: -123px;
          font-size: 14px;
        }
        .chmtf{
          background: #F1F3F9;
          border-radius: 64px;
          max-width: 95%;
          margin: auto;
          //box-shadow: 0px 0px 10px rgb(0 0 0 / 20%);
        }

        .fcwh {
          padding: 100px 20px;
        }

        .fcwh__heading {
          text-align: center;
        }

        .wwab {
          padding: 0 20px;
        }

        .haau {
          padding: 0 20px;
        }

        .cuwm {
          margin-top: 500px;
          padding: 0;
        }

        @media ${viewports.smOrSmaller} {
          .learn-more-sticky {
            display: none;
          }
        }

        @media ${viewports.smOnly} {
          .cuwm {
            //margin-top: 400px;
          }
        }

        @media ${viewports.mdOrBigger} {
          .cuwm {
            margin-top: 200px;
            padding: 0 20px;
          }
        }

        @media ${viewports.lgOrBigger} {
          .haau {
            margin-top: 150px;
          }
        }

        @media ${viewports.xlOrBigger} {
          .fcwh {
            padding: 160px 150px 20px 150px;
          }

          .fcwh__heading {
            text-align: left;
          }

          .haau,
          .wwab,
          .chmtf,
          .cuwm {
            padding: 50px;
          }
        }
      `}</style>
    </Layout>
  )
}

export default IndexPage

import React from "react"
import { colors, viewports } from "../../theme/vars"

const Styles = () => (
  <style jsx global>{`
  .feature-section__even,
  .feature-section__odd {
    align-items: center !important;
  }
  .feature__content{
      padding: 0 20px;
      text-align: center;
  }
  .app-store {
    max-width: 175px;
    margin-top: 20px;
  }

  @media ${viewports.mdOrSmaller} {
    .feature__description {
      margin-bottom:20px;
    }
    .feature__content {
      margin: 30px 0;
    }
    .feature__image{
      margin: 10px 0;
    }
  }
    
  @media ${viewports.mdOrBigger} {
    .feature__image{
      margin: 30px;
    }
    .feature__content {
      text-align: left;
    }
  }
  @media ${viewports.lgOrBigger} {
    .feature__content {
      max-width: 70%;
      text-align: left;
    }
  }

  `}</style>
)

export default Styles

import React from "react"
import { colors, viewports } from "../../theme/vars"

const OtherVsLambStyles = () => (
  <style jsx global>{`
    .ovsl {
      border-radius: 30px;
      padding: 0px 16px;
      position: relative;
      overflow-x: hidden;
      height: 100%
    }

    .ovsl h5.ant-typography{
      color: ${colors.text};
    }
    .ovsl h2.ant-typography {
      color: ${colors.primary};
    }

    .ovsl__info {
      position: relative;
      border-radius: 30px;
      background: ${colors.white};
      padding: 32px;
      display: flex;
      flex-direction: column;
      z-index: 0;
      box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.14),
        0px 41.7776px 33.4221px rgba(0, 0, 0, 0.10064),
        0px 22.3363px 17.869px rgba(0, 0, 0, 0.083455),
        0px 12.5216px 10.0172px rgba(0, 0, 0, 0.07),
        0px 6.6501px 5.32008px rgba(0, 0, 0, 0.056545),
        0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0393604);
    }
    .oval__info-pros-cons-wrapper {
    }
    .ovsl__info-cons--title,
    .ovsl__info-pros--title {
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      color: #3b3a40;
      margin-bottom: 30px;
    }
    .ovsl__info-pros--title img {
      width: 145px;
      height: auto;
      margin-left: 10px;
    }
    .ovsl__info ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }
    .ovsl__info li {
      margin: 25px 0;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #64626a;
      display: flex;
      align-items: end;
    }
    .ovsl__info li svg {
      margin-right: 18px;
      //max-width: 18px;
      flex: 0 1 10%;
    }
    .ovsl__info li span {
      flex: 0 1 90%;
    }

    .ovsl__info--vs {
      position: relative;
      border: 1px solid #e8e7e8;
      margin: 60px 0;
    }
    .ovsl__info--vs-label {
      text-align: center;
      background: #f5f6f9;
      border: 1px solid #e8e7e8;
      border-radius: 15px;
      width: 62px;
      height: 62px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      text-align: center;
      color: #8e8c95;
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      top: -31px;
    }

    .ovsl__cta {
      border-radius: 19px;
      padding: 0px 70px;
    }

    .ovsl__footer {
      position: relative;
      margin-top: -20%;
      width: 100%;
      text-align: center;
      color: ${colors.text};
      padding: 64px 0;
      bottom: 0;
    }
    .ovsl__footer-stat {
      font-weight: bold;
      font-size: 52px;
      line-height: 120%;
      margin-bottom: 40px;
      text-align: center;
    }
    .ovsl__footer-label {
      font-weight: 500;
      font-size: 24px;
      margin-top: -15px;
      text-align: center;
    }
    .ovsl__footer-description {
      font-weight: normal;
      font-size: 16px;
      margin-top: 24px;
    }

    .ovsl__background {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
      width: 100%;
    }
    .ovsl__background-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(
        180deg,
        #2796d6 52.12%,
        rgba(102, 176, 131, 0) 100%
      );
    }

    .ovsl_footer--text{
      width: 80%;
      margin: auto;
    }
    .app_store_logo {
      max-width: 200px; 
      margin-top: 10px; 
    }

    @media ${viewports.mdOrBigger} {
      .ovsl__cta {
        max-width: 340px;
        margin: 44px auto 0 auto;
      }

      .ovsl__footer-stat {
        font-size: 64px;
      }
    }

    @media ${viewports.lgOrBigger} {
      .ovsl {
        margin: 0 40px;
        min-height: 800px;
      }
      .ovsl__info {
        margin: 0 40px;
      }
      .ovsl_footer--text{
        width: 60%;
        margin: auto;
      }
    }

    @media ${viewports.xlOrBigger} {
      .oval__info-pros-cons-wrapper {
        display: flex;
        flex-direction: row;
      }

      .ovsl__info-cons--title {
        text-align: right;
        margin-right: 48px;
      }
      .ovsl__info-pros--title {
        text-align: left;
        margin-left: 35px;
      }
      .ovsl__info--vs {
        margin: 0 40px 10px 40px;
      }
      .ovsl__info--vs-label {
        top: -15px;
        left: -31px;
        right: auto;
        background: ${colors.white};
      }

      .ovsl__info-cons--items {
        text-align: right;
        //margin-right: -25px !important;
      }
      .ovsl__info-cons--items li {
        flex-direction: row-reverse;
        margin: 28px 0;
      }
      .ovsl__info-cons--items li svg {
        margin-right: 0;
      }
      .ovsl__info-cons--title {
        margin-bottom: 35px;
      }
      .ovsl__info-cons {
        flex: 0 1 50%;
      }
      .ovsl__info-pros {
        flex: 0 1 50%;
      }
      .ovsl__info--vs {
        //flex: 0 1 2%;
      }
    }
  `}</style>
)

export default OtherVsLambStyles

import React from "react"
import { string } from "prop-types"
import { Row, Col, Typography } from "antd"
import { viewports } from "../../../theme/vars"

const { Title } = Typography

const HeadlineWithOverheadline = ({ heading, subHeading, style }) => {
  return (
    <div className="top-header" style={style}>
      <Row align="middle">
        <Col xs={24}>
          <Title className="hwo__sub-heading" level={5}>
            {heading}
          </Title>
        </Col>
      </Row>
      <Row justify="center">
        <Col xs={24}>
          <Title className="hwo__heading" level={2}>
          {subHeading}
          </Title>
        </Col>
      </Row>

      <style jsx global>{`
        .top-header {
          width: 100%;
          position: absolute;
          top: 10%; 
        }
        .hwo__sub-heading {
          text-align: center;
          color:#656F89 !important; 
        }
        .hwo__heading {
          text-align: center;
          top: 10%;
          position: absolute;
          width: 100%;
          padding: 0em 2em;
        }
        @media ${viewports.lgOrBigger} {
          .hwo__heading {
            padding: 0em 10em;
          }
      `}</style>
    </div>
  )
}

HeadlineWithOverheadline.propTypes = {
  heading: string.isRequired,
  subHeading: string.isRequired,
}

export default HeadlineWithOverheadline

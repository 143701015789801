import React from "react"
import { string, arrayOf, any, bool } from "prop-types"
import { Row, Col, Typography } from "antd"
import Styles from "../checkmate-features/styles"
import { StaticImage } from "gatsby-plugin-image"


const { Title } = Typography


const CheckMateFeatures = () => {

    return (
        <section className="chmtf" style={{ marginTop: 80, background: "white", boxShadow: 'none' }}>
            <Row align="center" justify="center" className="feature-section__odd">
            <Col xs={24} md={12}>
                    <StaticImage
                        // className="hero__image"
                        src="../../images/bp_animated_effects.png"
                        alt="hero background"
                        placeholder="none"
                    />
                </Col>
                <Col xs={24} md={12} className="feature__content">
                    <Title level={2}>
                    Help In The Moment Of A Crisis
                    </Title>
                    <div className="feature__description">The app offers an SOS button that is available to you 24/7. This tab was designed to help parents get through crisis moments, allowing Bryan to be readily there and waiting to walk you through your toughest parenting moments.</div>
                    <StaticImage
                        className="app-store"
                        src="../../images/app_store.png"
                        alt="hero background"
                        placeholder="none"
                    />
                </Col>
                
            </Row>


            <Styles />
        </section>
    )
}

CheckMateFeatures.propTypes = {
    heading: string.isRequired,
    subHeading: string.isRequired,
    features: arrayOf(any).isRequired,
    reverted: bool
}

CheckMateFeatures.defaultProps = {
    reverted: false,
}

export default CheckMateFeatures

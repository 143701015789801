import React from "react"
import { string, arrayOf, any, bool } from "prop-types"
import { Row, Col, Typography } from "antd"
import Styles from "./styles"
import { StaticImage } from "gatsby-plugin-image"


const { Title } = Typography


const CheckMateFeatures = () => {

    return (
        <section className="chmtf" style={{ marginTop: 20 }}>
            <Row align="center" justify="center" className="feature-section__odd">
                <Col xs={24} md={12} className="feature__content">
                    <Title level={2}>
                        Perform daily chek-ins
                    </Title>
                    <div className="feature__description">These daily check ins are designed to help you identify triggers, recognize patterns, and come up with a diagnosis and plan faster. </div>
                    <StaticImage
                        className="app-store"
                        src="../../images/app_store.png"
                        alt="hero background"
                        placeholder="none"
                    />
                </Col>
                <Col xs={24} md={12}>
                    <StaticImage
                        // className="hero__image"
                        src="../../images/bp_everyday_use.png"
                        alt="hero background"
                        placeholder="none"
                    />

                </Col>
            </Row>


            <Styles />
        </section>
    )
}

CheckMateFeatures.propTypes = {
    heading: string.isRequired,
    subHeading: string.isRequired,
    features: arrayOf(any).isRequired,
    reverted: bool
}

CheckMateFeatures.defaultProps = {
    reverted: false,
}

export default CheckMateFeatures

import React from "react"
import { string, any } from "prop-types"
import HeadlineWithOverheadline from "../typography/headline-with-overheadline"
import OtherVsLambStyles from "./styles"
import { StaticImage } from "gatsby-plugin-image"


const LargeImageSection = props => {
 
  return (
    <section className="ovsl">
      <StaticImage
        className="hero__image"
        src="../../images/bp_safe_hands.jpg"
        alt="hero background"
        placeholder="none"
        style
      />

      <HeadlineWithOverheadline heading="Parenting Support just a click away!" subHeading="Spend time with your favorite coach on your favorite app" />

      <div className="ovsl__footer">
        <div className="ovsl_footer--text">
          <p>With over 20 years of experience Bryan Post is one of America’s 
            foremost child behavior experts and founder of the Post Institute 
            for Family-Centered Therapy. A renowned clinician, lecturer, and author, 
            Bryan has traveled throughout the world providing expert treatment 
            and consultation. An internationally recognized specialist in the treatment 
            of emotional and behavioral disturbance, he specializes in a holistic family-based 
            treatment approach that addresses the underlying interactive dynamics of the entire 
            family.
          </p>
          <StaticImage
            className="app_store_logo"
            src="../../images/app_store.png"
            alt="app store logo"
            placeholder="none"
            style
          />
        </div>
      </div>

      <OtherVsLambStyles />
    </section>
  )
}

LargeImageSection.propTypes = {
  heading: string.isRequired,
  subHeading: string.isRequired,
  prosTitle: string.isRequired,
  consTitle: string.isRequired,
  pros: any.isRequired,
  cons: any.isRequired,
  footer: any.isRequired,
}

export default LargeImageSection

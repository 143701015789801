import React, { useContext } from "react"
import { string, any } from "prop-types"
import HeadlineWithOverheadline from "../typography/headline-with-overheadline"
import OtherVsLambStyles from "./styles"
import { StaticImage } from "gatsby-plugin-image"


const LargeImageSection = () => {

    return (
        <section className="ovsl">
            <StaticImage
                className="hero__image"
                src="../../images/bacground-mozaic.jpg"
                alt="hero background"
                placeholder="none"
                style
            />

            <HeadlineWithOverheadline
                heading="Interactive Content"
                subHeading="Attend virtual courses specifically curated for you"
                style={{ top: "1%" }}
            />
            <OtherVsLambStyles />
        </section>
    )
}

LargeImageSection.propTypes = {
    heading: string.isRequired,
    subHeading: string.isRequired,
    prosTitle: string.isRequired,
    consTitle: string.isRequired,
    pros: any.isRequired,
    cons: any.isRequired,
    footer: any.isRequired,
}

export default LargeImageSection

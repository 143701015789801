import React from "react"
import { string, arrayOf, any, bool } from "prop-types"
import { Row, Col, Typography } from "antd"
import Styles from "../checkmate-features/styles"
import { StaticImage } from "gatsby-plugin-image"


const { Title } = Typography


const CheckMateFeatures = () => {

    return (
        <section className="chmtf" style={{ marginTop: 20, background: "#4CA2F6", borderRadius: 64 }}>
            <Row align="center" justify="center" className="feature-section__odd">
                <Col xs={24} md={12} className="feature__content">
                    <Title level={2} style={{ color: "white" }}>
                        Know how to better <br /> help your child
                    </Title>
                    <div className="feature__description" style={{ color: "white" }}>Our personalized self care activities designed and put together by Bryan himself.
                        Which includes one of our most popular features, BP’s favorites where you can access a variety of Bryans Favorite Playlist, Daily Affirmations recorded using Bryans voice, and super unique grounding techniques where Bryan tells stories to help take your mind off of the now.
                    </div>
                    <StaticImage
                        className="app-store"
                        src="../../images/app_store.png"
                        alt="hero background"
                        placeholder="none"
                    />
                </Col>
                <Col xs={24} md={12}>
                    <StaticImage
                        className="hero__image"
                        src="../../images/bp_know_better.png1.png"
                        alt="hero background"
                        placeholder="none"
                        style={{ padding: 20, borderRadius: 64, maxHeight: "500px" }}
                    />

                </Col>
            </Row>


            <Styles />
        </section>
    )
}

CheckMateFeatures.propTypes = {
    heading: string.isRequired,
    subHeading: string.isRequired,
    features: arrayOf(any).isRequired,
    reverted: bool
}

CheckMateFeatures.defaultProps = {
    reverted: false,
}

export default CheckMateFeatures
